import { ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

import fields from './tableFieldsCatalogs'

export default function useCatalogos() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  const {
    usuarioColumns,
    articuloColumns,
    clientesColumns,
    coloresColumns,
    cuentasContablesColumns,
    empresasColumns,
    formasPagoColumns,
    impuestosColumns,
    monedasColumns,
    proveedoresColumns,
    sucursalesColumns,
    temporadasColumns,
    tiposComprobantesColumns,
    tiposPagoColumns,
    tiposRelacionesColumns,
    ubicacionesColumns,
    cfdiColumns,
  } = fields()

  const refCatalogsList = ref([
    {
      title: 'Usuarios', tablename: 'usuarios', fields: usuarioColumns, data: [],
    },
    {
      title: 'Articulos', tablename: 'articulo', fields: articuloColumns, data: [],
    },
    {
      title: 'Clientes', tablename: 'cliente', fields: clientesColumns, data: [],
    },
    {
      title: 'Colores', tablename: 'color', fields: coloresColumns, data: [],
    },
    {
      title: 'Cuentas Contables', tablename: 'cuentasContable', fields: cuentasContablesColumns, data: [],
    },
    {
      title: 'Empresas', tablename: 'empresa', fields: empresasColumns, data: [],
    },
    {
      title: 'Formas de Pago', tablename: 'formasPago', fields: formasPagoColumns, data: [],
    },
    {
      title: 'Impuestos', tablename: 'impuesto', fields: impuestosColumns, data: [],
    },
    {
      title: 'Monedas', tablename: 'monedas', fields: monedasColumns, data: [],
    },
    {
      title: 'Proveedores', tablename: 'proveedor', fields: proveedoresColumns, data: [],
    },
    {
      title: 'Sucursales', tablename: 'sucursal', fields: sucursalesColumns, data: [],
    },
    {
      title: 'Temporadas', tablename: 'temporada', fields: temporadasColumns, data: [],
    },
    {
      title: 'Tipos de Comprobante', tablename: 'tiposComprobante', fields: tiposComprobantesColumns, data: [],
    },
    {
      title: 'Tipos de Pago', tablename: 'tiposPago', fields: tiposPagoColumns, data: [],
    },
    {
      title: 'Tipos de Relaciones', tablename: 'tiposRelacion', fields: tiposRelacionesColumns, data: [],
    },
    {
      title: 'Ubicaciones', tablename: 'ubicacion', fields: ubicacionesColumns, data: [],
    },
    {
      title: 'Usos de CFDI', tablename: 'usosCfdi', fields: cfdiColumns, data: [],
    },
  ])

  const refSelectedCatalog = ref({
    title: 'Usuarios', tablename: 'usuarios', fields: usuarioColumns, data: [],
  })

  const perPage = ref(25)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const loader = ref(true)

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchCatalog = () => {
    store
      .dispatch('app-store-catalogs/fetchCatalogs', {
        catalog: refSelectedCatalog.value.tablename,
      })
      .then(response => {
        loader.value = false
        const { data } = response
        refSelectedCatalog.value.data = data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo informacion',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const handleChangeCatalog = tab => {
    refSelectedCatalog.value = tab
    fetchCatalog()
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  return {
    fetchCatalog,
    handleChangeCatalog,

    refCatalogsList,
    refSelectedCatalog,

    perPage,
    currentPage,
    totalInvoices,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    loader,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  }
}
