<template>
  <b-card
    no-body
  >
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col
              md="2"
            >
              <b-button
                v-for="tab in refCatalogsList"
                :key="tab.title"
                :variant="tab.title === refSelectedCatalog.title ? 'primary' : 'flat-primary'"
                block
                @click="handleChangeCatalog(tab)"
              >
                {{ tab.title }}
              </b-button>
            </b-col>
            <b-col
              md="10"
            >
              <catalog-table
                :selected-catalog="refSelectedCatalog"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import CatalogTable from './tables/CatalogTable.vue'
import store from '@/store'
import useCatalogos from './useCatalogs'
import catalogsStoreModule from './catalogsStoreModule'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    // componenents
    CatalogTable,
  },
  setup() {
    const CATALOG_APP_STORE_MODULE_NAME = 'app-store-catalogs'

    // Register module
    if (!store.hasModule(CATALOG_APP_STORE_MODULE_NAME)) store.registerModule(CATALOG_APP_STORE_MODULE_NAME, catalogsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CATALOG_APP_STORE_MODULE_NAME)) store.unregisterModule(CATALOG_APP_STORE_MODULE_NAME)
    })

    const {
      fetchCatalog,
      handleChangeCatalog,

      refCatalogsList,
      refSelectedCatalog,

      perPage,
      currentPage,
      totalInvoices,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      loader,

      statusFilter,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      refetchData,
    } = useCatalogos()

    fetchCatalog()

    return {
      fetchCatalog,
      handleChangeCatalog,

      refCatalogsList,
      refSelectedCatalog,

      perPage,
      currentPage,
      totalInvoices,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      loader,

      statusFilter,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      refetchData,
    }
  },
}
</script>

<style scoped>
.w-10 {
  width: 10%;
}
</style>
