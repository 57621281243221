<template>
  <div
    class="div-table"
  >
    <b-card
      no-body
      class="border"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <span class="align-middle ml-50">{{ selectedCatalog.title }}</span>
        </b-card-title>
        <b-button
          variant="primary"
        >
          Agregar {{ selectedCatalog.title }}
        </b-button>
      </b-card-header>
      <b-table
        :items="selectedCatalog.data"
        :fields="selectedCatalog.fields"
        hover
        responsive
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit {{ data.value }}</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
  },
  props: {
    selectedCatalog: {
      type: Object,
      required: true,
    },
  },
  setup() {},
}
</script>

<style scoped>
.div-table {
  max-width: 121rem;
}
</style>
