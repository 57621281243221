export default function fields() {
  const empresasColumns = [
    { key: 'empresaID', label: '#', sortable: true },
    { key: 'nombreEmpresa', label: 'Nombre', sortable: true },
    { key: 'rfc', label: 'RFC', sortable: true },
    { key: 'direccion', label: 'Direccion', sortable: true },
    { key: 'telefono', label: 'Telefono', sortable: true },
    { key: 'email', label: 'Correo', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.empresaID}`,
    },
  ]

  const sucursalesColumns = [
    { key: 'sucursalID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'nombreSucursal', label: 'Sucursal', sortable: true },
    { key: 'direccionSucursal', label: 'Direccion', sortable: true },
    { key: 'telefonoSucursal', label: 'Telefono', sortable: true },
    { key: 'emailSucursal', label: 'Correo', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.sucursalID}`,
    },
  ]

  const coloresColumns = [
    { key: 'colorID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'nombre', label: 'Color', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.colorID}`,
    },
  ]

  const temporadasColumns = [
    { key: 'temporadaID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'nombre', label: 'Temporada', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.temporadaID}`,
    },
  ]

  const usuarioColumns = [
    { key: 'userID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'nombre', label: 'Usuario', sortable: true },
    { key: 'cargo', label: 'Cargo', sortable: true },
    { key: 'perfil', label: 'Perfil', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.userID}`,
    },
  ]

  const articuloColumns = [
    { key: 'articuloID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'sku', label: 'SKU', sortable: true },
    { key: 'marca', label: 'Marca', sortable: true },
    { key: 'linea', label: 'Linea', sortable: true },
    { key: 'modelo', label: 'Modelo', sortable: true },
    { key: 'base', label: 'Base', sortable: true },
    { key: 'estilo', label: 'Estilo', sortable: true },
    { key: 'colorID', label: 'Color', sortable: true },
    { key: 'talla', label: 'Talla', sortable: true },
    { key: 'temporada', label: 'Temporada', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    { key: 'proveedor', label: 'Proveedor', sortable: true },
    { key: 'costoCompra', label: 'Costo Compra', sortable: true },
    { key: 'costoVentaPuntoVenta', label: 'Costo Venta - Punto de Venta', sortable: true },
    { key: 'costoVentaEcommerce', label: 'Costo Venta Ecommerce', sortable: true },
    { key: 'moda', label: 'Moda', sortable: true },
    { key: 'nuevo', label: 'Nuevo', sortable: true },
    { key: 'descontinuado', label: 'Descontinuado', sortable: true },
    { key: 'cuentaContable', label: 'Cuenta Contable', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.articuloID}`,
    },
  ]

  const clientesColumns = [
    { key: 'clienteID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'razonSocial', label: 'Razon Social', sortable: true },
    { key: 'nombre', label: 'Cliente', sortable: true },
    { key: 'apellidoPaterno', label: 'Apellido Paterno', sortable: true },
    { key: 'apellidoMaterno', label: 'Apellido Materno', sortable: true },
    { key: 'rfc', label: 'RFC', sortable: true },
    { key: 'direccion', label: 'Direccion', sortable: true },
    { key: 'cp', label: 'CP', sortable: true },
    { key: 'telefono', label: 'Telefono', sortable: true },
    { key: 'email', label: 'Correo', sortable: true },
    { key: 'limiteCredito', label: 'Limite de Credito', sortable: true },
    { key: 'metodoPago', label: 'Metodo de Pago', sortable: true },
    { key: 'formaPago', label: 'Forma de Pago', sortable: true },
    { key: 'usoCFDI', label: 'Uso de CFDI', sortable: true },
    { key: 'tipoMoralFisica', label: 'Tipo Moral Fisica', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.clienteID}`,
    },
  ]

  const cuentasContablesColumns = [
    { key: 'cuentaContableID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'codigo', label: 'Codigo', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.cuentaContableID}`,
    },
  ]

  const formasPagoColumns = [
    { key: 'formaPagoID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.formaPagoID}`,
    },
  ]

  const impuestosColumns = [
    { key: 'impuestoID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'nombre', label: 'Impuesto', sortable: true },
    { key: 'tasa', label: 'Tasa', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.impuestoID}`,
    },
  ]

  const monedasColumns = [
    { key: 'moneda', label: 'Moneda', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.moneda}`,
    },
  ]

  const proveedoresColumns = [
    { key: 'proveedorID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'nombre', label: 'Marca', sortable: true },
    { key: 'rfc', label: 'Base', sortable: true },
    { key: 'direccion', label: 'Estilo', sortable: true },
    { key: 'cp', label: 'Color', sortable: true },
    { key: 'telefono', label: 'Talla', sortable: true },
    { key: 'email', label: 'Temporada', sortable: true },
    { key: 'metodoPago', label: 'Proveedor', sortable: true },
    { key: 'formaPago', label: 'Costo Compra', sortable: true },
    { key: 'usoCFDI', label: 'Costo Venta - Punto de Venta', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.ubicacionID}`,
    },
  ]

  const tiposComprobantesColumns = [
    { key: 'tipoComprobanteID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.ubicacionID}`,
    },
  ]

  const tiposPagoColumns = [
    { key: 'tipoPagoID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.proveedorID}`,
    },
  ]

  const tiposRelacionesColumns = [
    { key: 'tipoRelacionID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'descripcion', label: 'Descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.tipoRelacionID}`,
    },
  ]

  const ubicacionesColumns = [
    { key: 'ubicacionID', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'sucursal', label: 'Sucursal', sortable: true },
    { key: 'nombre', label: 'Ubicacion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.ubicacionID}`,
    },
  ]

  const cfdiColumns = [
    { key: 'usoCFDI', label: '#', sortable: true },
    { key: 'empresaID', label: 'Empresa', sortable: true },
    { key: 'descripcion', label: 'descripcion', sortable: true },
    {
      key: 'actions', label: 'Actions', sortable: true, formatter: (val, key, item) => `${item.usoCFDI}`,
    },
  ]

  return {
    usuarioColumns,
    articuloColumns,
    clientesColumns,
    coloresColumns,
    cuentasContablesColumns,
    empresasColumns,
    formasPagoColumns,
    impuestosColumns,
    monedasColumns,
    proveedoresColumns,
    sucursalesColumns,
    temporadasColumns,
    tiposComprobantesColumns,
    tiposPagoColumns,
    tiposRelacionesColumns,
    ubicacionesColumns,
    cfdiColumns,
  }
}
